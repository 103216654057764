import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Col, Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import Modale from '../modal';
import './style.css'
import shuffledArray from '../../hook/shuffledarray';
// import Cookies from 'universal-cookie';

export default function UpcomingData(props) {
    const [upcoming, setUpcoming] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        axios.get('/api/results')
            .then(response => {
                // console.log(response.data)
                setUpcoming(response.data.data[0].results)
            })
            .catch(error => console.log(error));

    }, []);


    var shuffledarray = shuffledArray(upcoming);
    return (
        <>

            <Col className='upcomingTableContainer' >
                <Table responsive="sm" bordered={true} style={{ textAlign: 'center' }}>
                    <thead>
                        <tr style={{ background: ' #1C53A5', borderBottomStyle: 'hidden', color: 'white' }}>
                            <th>Date</th>
                            <th>League</th>
                            <th>Home Team</th>
                            <th>Away Team</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            shuffledarray.filter((data) => data.status !== 'canceled').map((data, index) => (
                                <tr key={index}>
                                    <td style={{ background: '#efefef' }}>
                                        {/* {moment(data.lastUpdated).tz("USA/California").format('DD-MM-YYYY')} */}
                                        {moment().format('dddd DD MMMM yyyy HH:MM')}
                                    </td>
                                    <td>
                                        {data.details.league}
                                    </td>
                                    <td>
                                        <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 700, margin: 0 }}>{data.teams.home.team}</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 700, margin: 0 }}>{data.teams.away.team}</p>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Col>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={props.signin}
            />
        </>
    )
}
