import React, { useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
import { sidebarData } from './sidebarData'
import Modale from '../modal';
import Cookies from 'universal-cookie';

export default function SideBar() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <div className=' sideBarContainer'>
            <div>
                <h4 className='betTypes'>
                    Bet Types
                </h4>
            </div>
            {
                cookie.get('token') ?
                    <>
                        <div className='types'>
                            {sidebarData?.map((data) =>
                                <p key={data.id} onClick={() => setModalShow(true)} style={{ cursor: 'pointer' }}>{data.title}</p>
                            )}
                        </div>
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        // signin={true}
                        />
                    </>
                    :
                    <>
                        <div className='types'>
                            {sidebarData?.map((data) =>
                                <p key={data.id} onClick={() => setModalShow(true)} style={{ cursor: 'pointer' }}>{data.title}</p>
                            )}
                        </div>
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            signin={true}
                        />
                    </>
            }


        </ div>

    )
}
