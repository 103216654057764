// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Cookies from 'universal-cookie';
import Modale from '../modal';
import { navLinks } from './navLinks';

export default function Navigation() {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);

    // const token = true;
    return (
        <Navbar expand="lg" fixed='top' style={{ marginLeft: 8, marginRight: 8 }}>
            <Container className="navContainer"  >
                <Navbar.Brand href="#">
                    {/* <strong style={{ color: 'white' }}>
                        LOGO
                    </strong> */}
                    <div className="logo"></div>
                </Navbar.Brand>
                <Navbar.Toggle style={{ backgroundColor: '#FFCC00', borderRadius: 100 }} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto" ></Nav>
                    {
                        cookie.get('token') ?
                            <>
                                <Nav
                                    className=" my-2 my-lg-0 linkContainer"
                                >
                                    <Nav.Link style={{ fontWeight: '700', color: 'white' }} onClick={() => setModalShow(true)}>home</Nav.Link>

                                    {navLinks?.map((data) =>
                                        <Nav.Link key={data.id} className={data.cName} onClick={() => setModalShow(true)}>{data.link}</Nav.Link>
                                    )}
                                </Nav>
                                <DropDown />
                                <Modale
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                // signin={true}
                                />

                            </>
                            :
                            <>
                                <Nav
                                    className=" my-2 my-lg-0 linkContainer"
                                >
                                    <Nav.Link style={{ fontWeight: '700', color: 'white' }} onClick={() => setModalShow(true)}>home</Nav.Link>
                                    {navLinks?.map((data) =>
                                        <Nav.Link key={data.id} className={data.cName} onClick={() => setModalShow(true)}>{data.link}</Nav.Link>
                                    )}

                                </Nav>
                                <Modale
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    signin={true}
                                />

                            </>
                    }


                </Navbar.Collapse>
            </Container>
        </Navbar >

    )
}
