import React from 'react'
import { Container } from 'react-bootstrap'
import Cookies from 'universal-cookie';
import UpcomingData from './upcomingData';


export default function Upcoming() {
    const cookie = new Cookies();

    return (
        <>
            <Container>
                <h4 style={{ color: '#1C53A5', marginBottom: 16 }}>Upcoming Games</h4>
                <div style={{ display: 'flex' }} >
                    {
                        cookie.get('token') ?
                            <>
                                <UpcomingData />
                            </>
                            :
                            <>
                                <UpcomingData signin={true} />
                            </>
                    }



                </div>

                <br></br>
            </Container>
        </>
    )
}
