import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { FaInstagramSquare } from 'react-icons/fa';
// import { FaYoutubeSquare } from 'react-icons/fa';
// import { FaGithubSquare } from 'react-icons/fa'
import Cookies from 'universal-cookie';


export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    const helpSupport = [
        {
            id: 1,
            title: 'Help Center',
        },
        {
            id: 2,
            title: 'Contact Us',
        },
        {
            id: 3,
            title: 'Privacy Policy',
        },
        {
            id: 4,
            title: 'FAQ',
        },

    ];

    const links = [
        {
            id: 1,
            title: 'Home'
        },
        {
            id: 2,
            title: 'Las Vegas Rules'
        },
        {
            id: 3,
            title: 'Reduced Juice'
        },
        {
            id: 4,
            title: 'Dictionary'
        },

    ]

    return (
        <Container style={{ backgroundColor: '#1C53A5', height: 'auto' }}>
            <Container>
                <Row style={{ color: 'white', marginTop: 32, marginLeft: 16 }} sm={2} md={3} xs={2} >
                    <Col xs={12} style={{ paddingBottom: 16 }}>
                        <h4 style={{ marginBottom: 16 }}>Help & Support</h4>
                        {
                            cookie.get('token') ?
                                <>
                                    {
                                        helpSupport?.map((item) => (
                                            <p key={item.id} style={{ opacity: '70%', cursor: 'pointer' }} onClick={() => setModalShow(true)}>{item.title}</p>
                                        ))
                                    }

                                </>
                                :
                                <>
                                    {
                                        helpSupport?.map((item) => (
                                            <p key={item.id} style={{ opacity: '70%', cursor: 'pointer' }} onClick={() => setModalShow(true)}>{item.title}</p>
                                        ))
                                    }

                                </>
                        }

                    </Col>
                    <Col xs={12}>
                        <h4>Links</h4>
                        {
                            cookie.get('token') ?
                                <>
                                    {links?.map((item) => (
                                        <p key={item.id} style={{ opacity: '70%', cursor: 'pointer' }} onClick={() => setModalShow(true)}>{item.title}</p>
                                    ))
                                    }


                                </>
                                :
                                <>
                                    {links?.map((item) => (
                                        <p key={item.id} style={{ opacity: '70%', cursor: 'pointer' }} onClick={() => setModalShow(true)}>{item.title}</p>
                                    ))
                                    }


                                </>
                        }
                    </Col>
                    <Col>
                        <Col>
                            <h4 style={{ marginBottom: 16 }}>Social Media</h4>
                        </Col>

                        <Col xs={12} style={{ marginTop: 32 }}>
                            <p style={{ fontSize: 14, opacity: '70%' }}>
                                18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au - www.gambleaware.nsw.gov.au
                            </p>
                        </Col>
                    </Col>
                </Row>
                <br></br>
                <Row style={{ textAlign: 'left', color: 'white', marginBottom: 32 }}>
                    <Col><p>Copyright © 2022 - All right reserved.</p> </Col>
                </Row>
            </Container>
        </Container>
    )
}
