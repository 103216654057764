import { Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import NewsData from './newsData';
import useViewport from "../../hook/useViewport";
import './style.css'

export default function News() {
    const cookie = new Cookies();
    const { width } = useViewport();


    return (
        <>
            <Container>
                <h4 style={{ color: '#1C53A5' }}>News</h4>
                <div className={(width < 600 ? 'mobileNewsContainer' : 'newsContainer')} >
                    {
                        cookie.get('token') ?
                            <>
                                <NewsData />
                            </>
                            :
                            <>
                                <NewsData signin={true} />
                            </>
                    }
                </div>

                <br></br>
            </Container>
        </>
    )
}
