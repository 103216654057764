import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap'
import moment from 'moment';
import './style.css'

export default function UserTable(props) {

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <h4 style={{ color: '#1C53A5' }}>{props.title}</h4>
                        <p>{props.team}</p>
                    </Col>
                    <Row className='gameTableContainer' >
                        <Table responsive bordered style={{ textAlign: 'center' }}>

                            <thead>
                                <tr className="tableHead" >
                                    <th>Date</th>
                                    <th>Home</th>
                                    <th>Away</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.data?.map((data, index) => (
                                        <tr key={index}>
                                            <td style={{ background: '#efefef' }}>
                                                {moment().format('dddd DD MMMM yyyy HH:MM')}
                                            </td>
                                            <td>
                                                <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 700, margin: 0 }}>{data.teams.home.team}</p>
                                            </td>
                                            <td>
                                                <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 700, margin: 0 }}>{data.teams.away.team}</p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Row>
            </Container>
        </>
    )
}
