import { Container, Row, Col } from 'react-bootstrap';
import './App.css';
import Navigation from './components/navigation'
import Slideshow from './components/slideshow';
import Buttons from './components/button';
import SideBar from './components/sidebar';
// import { useState } from 'react';
import TableContainer from './components/tableContainer';
import Cookies from 'universal-cookie';
import Footer from './components/Footer';
import News from './components/news';
import Upcoming from './components/upcoming';
import CanceledGames from './components/canceledgames';
import LoginModal from './components/loginmodal';
import { useState } from 'react';
import Modale from './components/modal';

function App() {
  const cookie = new Cookies();
  const [modalShow, setModalShow] = useState(true);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Slideshow />
      <Row>
        <Col>
          <Navigation />
        </Col>
      </Row>
      {cookie.get('token') ?
        <Modale
          show={modalShow}
          onHide={() => setModalShow(false)}
        // signin={props.signin}
        />
        :
        <Row>
          <Col>
            <div className="ButtonsContainer">
              <Buttons text='sign in' kind="orange" signin={true} />
              <Buttons text='sign up' kind="orange" signin={false} />
            </div>
          </Col>
        </Row>
      }
      <br></br>
      <Container>
        <Row>
          <Col lg={2} style={{ padding: 0 }}>
            <SideBar />
            <br></br>
          </Col>
          <Col lg={10} style={{ padding: 0 }}>
            <Col>
              <News />
            </Col>
            <Col>
              <Upcoming />
            </Col>
            <Col>
              <CanceledGames />
            </Col>
            <Row>
              <TableContainer />
            </Row>
          </Col>
        </Row>
      </Container>
      <br></br>
      <Row>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
