import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Modale from '../modal';
import Marquee from "react-fast-marquee";

// import Cookies from 'universal-cookie';

export default function NewsData(props) {
    const [news, setNews] = useState([]);
    const [modalShow, setModalShow] = useState(false);



    useEffect(() => {
        axios.get('/api/news')
            .then(response => {
                setNews(response.data.items);
                // console.log(response.data)
            })
            .catch(error => console.log(error));

    }, []);
    return (
        <>
            <Marquee gradient={false} speed={100} pauseOnHover={true}>
                {news?.map((data, index) => (
                    <div key={index} style={{ width: '400px', height: '50vh' }}>
                        <div style={{ padding: 10 }} onClick={() => setModalShow(true)}>
                            <img style={{ borderRadius: 12, width: 350, height: 230 }} src={data.enclosures[0].url} alt="news" />
                            <Col>
                                <p style={{ fontSize: 16, marginTop: 12, lineHeight: 1.3 }}>{data.title}</p>
                            </Col>
                            <Row>
                                <Col>
                                    <p style={{ fontSize: 12, marginTop: 8, lineHeight: 1.3, opacity: '50%' }}>{moment(data.published).format('dddd DD MMMM yyyy HH:MM')}</p>
                                </Col>
                                <Col>
                                    <p style={{ fontSize: 12, marginTop: 8, lineHeight: 1.3, opacity: '50%', color: '#1C53A5' }}>{data.category}</p>
                                </Col>
                            </Row>
                        </div>

                    </div>
                ))}
            </Marquee>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={props.signin}
            />
        </>
    )
}
